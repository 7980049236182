import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { collection, query, onSnapshot, orderBy } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { firestoreDB } from '../services/firebase';
import ChatListItem from './ChatListItem';
import useChatStore from '../store/chatStore';

const SidebarContainer = styled.div`
  width: 300px;
  background-color: #2c2c2c;
  padding: 20px;
  overflow-y: auto;
`;

const SidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  margin-bottom: 20px;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const SidebarTitle = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: bold;
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    color: #e74c3c;
  }
`;

const SearchInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #3a3a3a;
  border-radius: 20px;
  background-color: #333;
  color: white;
  width: 90%;
  margin-top: 10px;
  font-size: 16px;
`;

const ChatList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const Sidebar = () => {
  const [uids, setUids] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const { clearSelectedMessages } = useChatStore();

  useEffect(() => {
    if (!currentUser) return;
    
    const q = query(
      collection(firestoreDB, 'chats'),
      orderBy('lastrequest', 'desc')
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const chat_uids_Promises = querySnapshot.docs.map(async (doc) => {
        const dat = doc.data();
        return {uid: doc.id, data: dat}
      });

      Promise.all(chat_uids_Promises)
        .then(uids_data => {
          setUids(uids_data);
        })
        .catch(error => {
          console.error("Error processing chats:", error);
        });
    }, (error) => {
      console.error("Error fetching chats:", error);
    });

    return () => unsubscribe();
  }, [currentUser]);

  const handleChatSelect = (uid) => {
    clearSelectedMessages();
    navigate(`/chat/${uid}`);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Failed to log out", error);
    }
  };

  return (
    <SidebarContainer>
      <SidebarHeader>
        <HeaderRow>
          <SidebarTitle>Chats</SidebarTitle>
          <LogoutButton onClick={handleLogout} title="Logout">
            <span role="img" aria-label="logout">⎋</span>
          </LogoutButton>
        </HeaderRow>
        <SearchInput
          placeholder="Search chat..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SidebarHeader>
      <ChatList>
        {uids.map(d => (
          <ChatListItem
            key={d.uid}
            uid={d.uid}
            lectored={d.data.lectored}
            searchTerm={searchTerm}
            onSelect={() => handleChatSelect(d.uid)}
          />
        ))}
      </ChatList>
    </SidebarContainer>
  );
};

export default Sidebar;
