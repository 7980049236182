import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { get, ref, onValue } from "firebase/database";
import { getDoc, doc, collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { firestoreDB, realtimeDB } from "../services/firebase";
import { formatName } from '../utils';

const ChatItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;

  &:hover {
    background-color: #3a3a3a;
  }
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

const ChatInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChatName = styled.span`
  font-weight: ${props => props.allLectored ? '400' : '900'};
`;

const LastMessage = styled.span`
  font-size: 0.8em;
  color: #a0a0a0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  font-weight: ${props => props.isLectored ? '300' : '600'};
`;

const ChatListItem = ({ uid, searchTerm, onSelect }) => {
  const [profile, setProfile] = useState();
  const [lastMessage, setLastMessage] = useState('No messages yet');
  const [isLectored, setIsLectored] = useState(true);
  const [allLectored, setAllLectored] = useState(true);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const profileRef = ref(realtimeDB, `users/${uid}/profile`);
    const unsubscribeProfile = onValue(profileRef, (profileSnapshot) => {
      if (profileSnapshot.exists()) {
        const profileData = profileSnapshot.val();
        setProfile(profileData);
        setIsVisible(true || searchTerm === "" || formatName(profileData).toLowerCase().includes(searchTerm.toLowerCase()));
      } else {
        setProfile(null);
        setIsVisible(false);
      }
    });

    const messagesQuery = query(
      collection(firestoreDB, `chats/${uid}/messages`),
      orderBy('timestamp', 'desc'),
      limit(1)
    );

    const unsubscribeMessages = onSnapshot(messagesQuery, (snapshot) => {
      if (!snapshot.empty) {
        const lastMsg = snapshot.docs[0].data();
        setLastMessage(lastMsg.text);
        setIsLectored(lastMsg.isLectored);
      }
    });

    const allMessagesQuery = query(
      collection(firestoreDB, `chats/${uid}/messages`),
      orderBy('timestamp', 'desc')
    );

    const unsubscribeAllMessages = onSnapshot(allMessagesQuery, (snapshot) => {
      const allLectored = snapshot.docs.every(doc => doc.data().isLectored);
      setAllLectored(allLectored);
    });

    return () => {
      unsubscribeProfile();
      unsubscribeMessages();
      unsubscribeAllMessages();
    };
  }, [uid, searchTerm]);

  if (!isVisible) return null;

  const displayName = formatName(profile) || uid;

  return (
    <ChatItem onClick={onSelect}>
      <Avatar src={profile?.photo || `${process.env.PUBLIC_URL}/50.png`} alt={displayName} />
      <ChatInfo>
        <ChatName allLectored={allLectored}>{displayName}</ChatName>
        <LastMessage isLectored={isLectored}>{lastMessage}</LastMessage>
      </ChatInfo>
    </ChatItem>
  );
};

export default ChatListItem;
