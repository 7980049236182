import { create } from 'zustand';
import { ref, onValue } from "firebase/database";
import { collection, query, orderBy, onSnapshot, doc, getDoc, updateDoc } from 'firebase/firestore';
import { realtimeDB, firestoreDB } from '../services/firebase';
import { queryDiaCoach, translateText } from '../LLM/LLM';

const useChatStore = create((set, get) => ({
  uid: null,
  profile: null,
  lectored: false,
  messages: [],
  messageListener: null,
  selectedMessages: [],
  selectedMessageIds: new Set(),
  customInfo: '',
  currentResponse: '',
  diacoachMessage: '',
  isAutoTranslate: false,

  setDiacoachMessage: (msg) => set({ diacoachMessage: msg }),

  setMessages: (messages) => set({ messages }),
  setSelectedMessages: (selectedMessages) => set({ selectedMessages }),
  setSelectedMessageIds: (ids) => set(state => ({ 
    selectedMessageIds: typeof ids === 'function' ? ids(state.selectedMessageIds) : new Set(ids) 
  })),
  
  setCustomInfo: (info) => set({ customInfo: info }),
  setCurrentResponse: (response) => set({ currentResponse: response }),

  saveUid: uid => set({ uid }),

  setAutoTranslate: (value) => set({ isAutoTranslate: value }),

  clearSelectedMessages: () => {
    set({ selectedMessages: [], selectedMessageIds: new Set() });
  },

  fetchProfile: (uid) => {
    const profileRef = ref(realtimeDB, `users/${uid}/profile`);
  
    onValue(profileRef, (snapshot) => {
      if (snapshot.exists()) {
        const profileData = snapshot.val();
        set({ profile: profileData });
      } else {
        set({ profile: null });
      }
    }, (error) => {
      console.error('Error fetching profile:', error);
      set({ profile: null });
    });
  },

  getUserLanguage: () => {
    const { profile } = get();
    return profile && profile.language ? profile.language.toLowerCase().slice(0, 2) : 'en';
  },

  fetchMessages: (uid) => {
    const q = query(collection(firestoreDB, `chats/${uid}/messages`), orderBy('timestamp'));
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messageList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      set({ messages: messageList });
    });
  
    set({ messageListener: unsubscribe });
  
    return unsubscribe;
  },
  
  removeMessageListener: () => {
    const { messageListener } = get();
    if (messageListener) {
      messageListener();
      set({ messageListener: null });
    }
  },

  clearChat: () => set({ profile: null, messages: [], selectedMessages: [], selectedMessageIds: new Set() }),

  queryLLMDiaCoach: async (fixText) => {
    const { customInfo, selectedMessages, setDiacoachMessage } = get();
    const response = await queryDiaCoach(fixText, customInfo, selectedMessages, setDiacoachMessage, set);
    set({ customInfo: '', currentResponse: '' });
  },

  fetchAutoTranslateStatus: async (uid) => {
    try {
      const chatDoc = await getDoc(doc(firestoreDB, `chats/${uid}`));
      if (chatDoc.exists()) {
        const chatData = chatDoc.data();
        set({ isAutoTranslate: chatData.isAutoTranslate || false });
      } else {
        set({ isAutoTranslate: false });
      }
    } catch (error) {
      console.error("Error fetching auto translate status:", error);
      set({ isAutoTranslate: false });
    }
  },

  translateMessage: async (messageId, targetLanguage) => {
    const { messages, uid } = get();
    const message = messages.find(m => m.id === messageId);
    
    if (!message) return;

    const translation = await translateText(message.text, targetLanguage);
    
    const updatedMessage = { ...message, translation };
    
    // Update the message in Firestore
    await updateDoc(doc(firestoreDB, `chats/${uid}/messages/${messageId}`), { translation });

    // Update the local state
    set(state => ({
      messages: state.messages.map(m => m.id === messageId ? updatedMessage : m)
    }));
  },

  translateAllMessages: async (targetLanguage) => {
    const { messages, uid } = get();
    
    for (const message of messages) {
      if (!message.translation) {
        await get().translateMessage(message.id, targetLanguage);
      }
    }
  },

}));

export default useChatStore;
