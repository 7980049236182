import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, realtimeDB } from '../services/firebase';
import { ref, onValue } from 'firebase/database';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';

const AuthContext = createContext();
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      if (user) {
        const userRef = ref(realtimeDB, `users/${user.uid}/profile`);
        onValue(userRef, (snapshot) => {
          setUserProfile(snapshot.val());
        });
      } else {
        setUserProfile(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userProfile,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}