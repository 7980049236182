import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref } from 'firebase/database';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyChSD_9K4I9CzRf1iIPNw448_h7Fvmgm2w",
  authDomain: "diabtrend-db.firebaseapp.com",
  databaseURL: "https://diabtrend-db.firebaseio.com",
  projectId: "diabtrend-db",
  storageBucket: "diabtrend-db.appspot.com",
  messagingSenderId: "589662669222",
  appId: "1:589662669222:web:e3c0e7dc73874542f46767",
  measurementId: "G-PLG1GY4H5J"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestoreDB = getFirestore(app);
// console.log("Firestore initialized:", firestoreDB);
export const realtimeDB = getDatabase(app);
const rootRef = ref(realtimeDB);
// console.log("Root reference created:", rootRef);

export const functions = getFunctions(app);

const notificationMessages = {
  en: { title: "DiaCoach", body: "You have a new message!" },
  es: { title: "DiaCoach", body: "¡Tienes un nuevo mensaje!" },
  de: { title: "DiaCoach", body: "Sie haben eine neue Nachricht!" },
  fr: { title: "DiaCoach", body: "Vous avez un nouveau message !" },
  it: { title: "DiaCoach", body: "Hai un nuovo messaggio!" },
  nl: { title: "DiaCoach", body: "Je hebt een nieuw bericht!" },
  tr: { title: "DiaCoach", body: "Yeni bir mesajınız var!" },
  sv: { title: "DiaCoach", body: "Du har ett nytt meddelande!" },
  no: { title: "DiaCoach", body: "Du har en ny melding!" },
  ro: { title: "DiaCoach", body: "Ai un mesaj nou!" },
  hu: { title: "DiaCoach", body: "Új üzeneted érkezett!" },
  pt: { title: "DiaCoach", body: "Você tem uma nova mensagem!" },
  pl: { title: "DiaCoach", body: "Masz nową wiadomość!" }
};

export const sendNotificationToUid = async (uid, userLanguage = 'en') => {
  console.log("Calling sendNotification with:", { uid, userLanguage });
  const sendNotification = httpsCallable(functions, 'sendNotificationv2');

  // Get the appropriate message for the user's language, fallback to English if not available
  const message = notificationMessages[userLanguage] || notificationMessages['en'];

  const notification = {
    notification: {
      title: message.title,
      body: message.body,
    },
    data: {
      notifee: JSON.stringify({
        android: { channelId: 'default' },
        id: 'NOTIFICATION_'+ Date.now(),
      }),
      type: 'WEBSITE_NOTIFICATION',
    },
  };

  try {
    const result = await sendNotification({ uid, notification });
    return result;
  } catch (error) {
    console.error("Error name:", error.name);
    console.error("Error message:", error.message);
    console.error("Error code:", error.code);
    console.error("Error details:", error.details);
    throw error;
  }
};

