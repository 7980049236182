import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useChatStore from '../store/chatStore';
import ReactMarkdown from 'react-markdown';
import LectoredIcon from './LectoredIcon';
import { updateDoc, doc, deleteDoc } from "firebase/firestore";
import { firestoreDB } from "../services/firebase";

const MessageListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;

  /* Custom scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #6b6b6b #3a3a3a;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #3a3a3a;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
    border-radius: 4px;
    border: 2px solid #3a3a3a;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: ${({ isSenderMessage }) => isSenderMessage ? 'row' : 'row-reverse'};
  align-items: flex-start;
  margin-bottom: 8px;
  gap: 8px;
`;

const MessageBubble = styled.div`
  background-color: ${({ isSenderMessage }) => isSenderMessage ? '#2ecc71' : '#3498db'};
  color: white;
  border-radius: 18px;
  padding: 6px 10px;
  word-wrap: break-word;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  border: 2px solid transparent;

  ${({ isSelected }) => isSelected && `
    border-color: #f39c12;
  `}

  &:hover {
    background-color: ${({ isSenderMessage }) => isSenderMessage ? '#27ae60' : '#2980b9'};
  }
`;

const TranslationSeparator = styled.hr`
  border: 0;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 8px 0;
`;

const OriginalText = styled(ReactMarkdown)`
  p {
    margin: 0;
    padding: 0;
  }
  
  * :first-child {
    margin-top: 0;
  }
  
  * :last-child {
    margin-bottom: 0;
  }
`;

const TranslatedText = styled(OriginalText)`
  font-style: italic;
  font-size: 0.9em;
  opacity: 0.9;
`;

const SelectionIndicator = styled.div`
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f39c12;
  opacity: ${({ isSelected }) => isSelected ? 1 : 0};
  transition: opacity 0.2s;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 86px;
  right: 30px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  z-index: 1000;

  &:hover {
    background-color: #c0392b;
  }

  &:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
`;

// Temporary translation function (placeholder)
const getTemporaryTranslation = (text) => {
  return `[HU] ${text.split(' ').reverse().join(' ')}`;
};

const MessageItem = ({ message, isSenderMessage, isSelected, onSelect, onLectoredToggle, showTranslation }) => (
  <MessageWrapper isSenderMessage={isSenderMessage}>
    {!isSenderMessage && (
      <LectoredIcon
        active={message.isLectored}
        onClick={() => onLectoredToggle(message.id)}
        activeIconSrc={`${process.env.PUBLIC_URL}/Lectored.png`} 
        inactiveIconSrc={`${process.env.PUBLIC_URL}/Unlectored.png`}
        size="20px"
      />
    )}
    <MessageBubble 
      isSenderMessage={isSenderMessage} 
      isSelected={isSelected}
      onClick={() => onSelect(message.id)}
    >
      <OriginalText>{message.text}</OriginalText>
      {showTranslation && message.translation && (
        <>
          <TranslationSeparator />
          <TranslatedText>{message.translation}</TranslatedText>
        </>
      )}
      <SelectionIndicator isSelected={isSelected} />
    </MessageBubble>
    {isSenderMessage && (
      <LectoredIcon
        active={message.isLectored}
        onClick={() => onLectoredToggle(message.id)}
        activeIconSrc={`${process.env.PUBLIC_URL}/Lectored.png`} 
        inactiveIconSrc={`${process.env.PUBLIC_URL}/Unlectored.png`}
        size="20px"
      />
    )}
  </MessageWrapper>
);

const MessageList = () => {
  const { uid } = useParams();
  const {
    messages,
    setSelectedMessages,
    setMessages,
    selectedMessageIds,
    setSelectedMessageIds,
    isAutoTranslate,
    translateAllMessages,
    language
  } = useChatStore();
  const messageListRef = useRef(null);

  useEffect(() => {
    // Clear selected messages when uid changes
    setSelectedMessages([]);
    setSelectedMessageIds(new Set());
  }, [uid, setSelectedMessages, setSelectedMessageIds]);

  const deleteSelectedMessages = async () => {
    const updatedMessages = messages.filter(message => !selectedMessageIds.has(message.id));
    setMessages(updatedMessages);

    for (const messageId of selectedMessageIds) {
      await deleteDoc(doc(firestoreDB, `chats/${uid}/messages/${messageId}`));
    }

    setSelectedMessageIds(new Set());
    setSelectedMessages([]);
  };

  const toggleMessageSelection = (messageId) => {
    setSelectedMessageIds(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(messageId)) {
        newSelected.delete(messageId);
      } else {
        newSelected.add(messageId);
      }
      return newSelected;
    });
  };

  const toggleMessageLectored = async (messageId) => {
    const msg = messages.find(message => message.id === messageId);

    try {
      await updateDoc(doc(firestoreDB, `chats/${uid}/messages/${messageId}`), { 
        isLectored: !msg.isLectored
      });
    } catch (error) {
      console.error("Error updating lectored status:", error);
    }
    setMessages(messages.map(message => 
        message.id === messageId 
          ? {...message, isLectored: !msg.isLectored} 
          : message
      )
    );
  };

  useEffect(() => {
    setSelectedMessages(messages.filter(message => selectedMessageIds.has(message.id)))
  }, [selectedMessageIds, messages, setSelectedMessages]);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {    
    if (isAutoTranslate && language !== 'hu') {
      translateAllMessages('Hungarian'); // Or any default target language
    }
  }, [isAutoTranslate, translateAllMessages, language]);


  return (
    <MessageListContainer ref={messageListRef}>
      {selectedMessageIds.size > 0 && (
        <DeleteButton onClick={deleteSelectedMessages}>
          Delete Selected ({selectedMessageIds.size})
        </DeleteButton>
      )}
      {messages.map(message => (
        <MessageItem
          key={message.id}
          message={message}
          isSenderMessage={message.sender === uid}
          isSelected={selectedMessageIds.has(message.id)}
          onSelect={toggleMessageSelection}
          onLectoredToggle={toggleMessageLectored}
          showTranslation={isAutoTranslate && language !== 'hu'}
        />
      ))}
    </MessageListContainer>
  );
};

export default MessageList;
