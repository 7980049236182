import React, { useState } from "react";
import styled from "styled-components";

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Icon = styled.img`
  width: ${props => props.size || '30px'};
  height: ${props => props.size || '30px'};
`;

const LectoredIcon = ({ onClick, active, activeIconSrc, inactiveIconSrc, size }) => {
  const [isloading, setLoading] = useState(active);

  const handleClick = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(); 
    setLoading(!isloading);
  }, [onClick]);

  return (
    <IconContainer onClick={handleClick}>
      <Icon src={isloading ? activeIconSrc : inactiveIconSrc} alt="Lectored icon" 
        size={size} />
    </IconContainer>
  );
};


export default LectoredIcon;
