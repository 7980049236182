import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import useChatStore from '../store/chatStore';
import { formatName } from '../utils';
import LectoredIcon from './LectoredIcon';
import { updateDoc, getDoc, doc } from "firebase/firestore";
import { firestoreDB, realtimeDB } from "../services/firebase";
import { ref, get } from "firebase/database";

const Header = styled.div`
  background-color: #1e1e1e;
  color: white;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #2c2c2c;
`;

const ChatInfo = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ChatName = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
`;

const HealthInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  margin-top: 5px;
`;

const HealthItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const GenderText = styled.span`
  font-size: 16px;
  font-weight: 600;
`;

const IconGroup = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

const AutoTranslateSwitch = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const SwitchSlider = styled.span`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }

  ${SwitchInput}:checked + & {
    background-color: #2196F3;
  }

  ${SwitchInput}:checked + &:before {
    transform: translateX(20px);
  }
`;

const SwitchLabel = styled.span`
  margin-left: 10px;
  font-size: 14px;
`;

const LanguageDisplay = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  font-size: 16px;
  cursor: help;
`;

const LanguageEmoji = styled.span`
  margin-right: 5px;
  font-size: 20px;
`;

const languageEmojis = {
  'en': '🇬🇧',
  'hu': '🇭🇺',
  'de': '🇩🇪',
  'es': '🇪🇸',
  'fr': '🇫🇷',
  'it': '🇮🇹', 
  'nl': '🇳🇱', 
  'no': '🇳🇴', 
  'pl': '🇵🇱', 
  'pt': '🇵🇹', 
  'ro': '🇷🇴', 
  'se': '🇸🇪', 
  'tr': '🇹🇷',
};

const languageNames = {
  'en': 'English',
  'hu': 'Hungarian',
  'de': 'German',
  'es': 'Spanish',
  'fr': 'French',
  'it': 'Italian', 
  'nl': 'Dutch', 
  'no': 'Norwegien', 
  'pl': 'Polish', 
  'pt': 'Portuguese', 
  'ro': 'Romanian', 
  'se': 'Swedish', 
  'tr': 'Türkish',
};
const formatDiabetesType = (type) => {
  switch (type) {
    case "TYPE_1": return "T1";
    case "TYPE_2": return "T2";
    case "PREDABETES": return "Prediabetes";
    case "PREDIABETES": return "Prediabetes";
    case "GESTATIONAL": return "GEST";
    case "none": return "Not";
    default: return type;
  }
};

const calculateAge = (birthDate) => {
  const today = new Date();
  const birthDateObj = new Date(birthDate*1000);
  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();
  
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
    age--;
  }
  
  return age;
};

const getLanguageCode = (languageString) => {  return  languageString.toLowerCase().slice(0, 2); };

const ChatHeader = ({ uid }) => {
  const { profile, setAutoTranslate } = useChatStore();
  const [lectored, setLectored] = useState(false);
  const [healthData, setHealthData] = useState(null);
  const [isAutoTranslate, setIsAutoTranslate] = useState(false);
  const [language, setLanguage] = useState('');
  const [age, setAge] = useState(null);

  const toggleMessageLectored = () => {
    updateDoc(doc(firestoreDB, `chats/${uid}`), { lectored: !lectored });
    setLectored(!lectored);
  };

  const handleAutoTranslateToggle = () => {
    const newIsAutoTranslate = !isAutoTranslate;
    setIsAutoTranslate(newIsAutoTranslate);
    updateDoc(doc(firestoreDB, `chats/${uid}`), { isAutoTranslate: newIsAutoTranslate });
    setAutoTranslate(newIsAutoTranslate);
  };

  useEffect(() => {
    const fetchChatData = async () => {
      try {
        const chatDoc = await getDoc(doc(firestoreDB, `chats/${uid}`));
        if (chatDoc.exists()) {
          const chatData = chatDoc.data();
          setLectored(chatData.lectored || false);
          setIsAutoTranslate(chatData.isAutoTranslate || false);
          setAutoTranslate(chatData.isAutoTranslate || false);
        } else {
          setLectored(false);
          setIsAutoTranslate(false);
          setAutoTranslate(false);
        }
      } catch (error) {
        console.error("Error fetching chat data:", error);
        setLectored(false);
        setIsAutoTranslate(false);
        setAutoTranslate(false);
      }
    };

    fetchChatData();

    // Fetch health data and language from Realtime Database
    const userRef = ref(realtimeDB, `users/${uid}`);
    get(userRef).then((snapshot) => {
      if (snapshot.exists()) {
        const userData = snapshot.val();
        if (userData.health) {
          setHealthData(userData.health);
          if (userData.health.birthDate) {
            setAge(calculateAge(userData.health.birthDate));
          }
        }
        if (userData.profile && userData.profile.language) {
          setLanguage(getLanguageCode(userData.profile.language));
        }
      }
    }).catch((error) => {
      console.error("Error fetching user data:", error);
    });
  }, [uid, setAutoTranslate]);

  const displayName = formatName(profile) || uid;

  return (
    <Header>
      <ChatInfo>
        <Avatar src={profile?.photo || `${process.env.PUBLIC_URL}/50.png`} alt={displayName} />
        <UserInfo>
          <ChatName>{displayName}</ChatName>
          {healthData && (
            <HealthInfo>
              {healthData.diabetesType && (
                <HealthItem title="Diabetes Type">
                  <span role="img" aria-label="diabetes">🩸</span>
                  {formatDiabetesType(healthData.diabetesType)}
                </HealthItem>
              )}
              {healthData.gender && (
                <HealthItem title="Gender">
                  <span role="img" aria-label="gender">{healthData.gender === 'FEMALE' ? '♀️' : '♂️'}</span>
                  <GenderText>{healthData.gender}</GenderText>
                </HealthItem>
              )}
              {age !== null && (
                <HealthItem title="Age">
                  <span role="img" aria-label="age">🎂</span>
                  {age} years
                </HealthItem>
              )}
              {healthData.height && (
                <HealthItem title="Height">
                  <span role="img" aria-label="height">📏</span>
                  {healthData.height} cm
                </HealthItem>
              )}
              {healthData.weight && (
                <HealthItem title="Weight">
                  <span role="img" aria-label="weight">⚖️</span>
                  {parseFloat(healthData.weight).toFixed(1)} kg
                </HealthItem>
              )}
            </HealthInfo>
          )}
        </UserInfo>
      </ChatInfo>
      <IconGroup>
        {language && (
          <LanguageDisplay title={languageNames[language] || language}>
            <LanguageEmoji>{languageEmojis[language] || '🌐'}</LanguageEmoji>
            {language.toUpperCase()}
          </LanguageDisplay>
        )}
        <AutoTranslateSwitch>
          <SwitchLabel>Auto Translate</SwitchLabel>
          <SwitchInput 
            type="checkbox"
            checked={isAutoTranslate}
            onChange={handleAutoTranslateToggle}
          />
          <SwitchSlider />
        </AutoTranslateSwitch>
        Lectored:
        <LectoredIcon onClick={toggleMessageLectored} active={lectored} 
                      activeIconSrc={`${process.env.PUBLIC_URL}/lectored.png`} 
                      inactiveIconSrc={`${process.env.PUBLIC_URL}/unlectored.png`}/>
      </IconGroup>
    </Header>
  );
};

export default ChatHeader;
