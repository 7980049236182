// src/services/LLM.js
import { OpenAI } from 'openai';

// const API_KEY = 'sk-proj-xfoLuX7UvevYLqkF8S64T3BlbkFJUusN8huXZ56nadZ6D0Lo';
// const API_KEY = 'sk-proj-xnoYLiExaVliuoeEqHdzT3BlbkFJcNg5vbeiHjrH4TebJrmr';
const API_KEY = 'sk-proj-Rzoc3QloIeCAKGS8pafgT3BlbkFJunEdDYsu6Ae9hoNR6Gvt';
const default_style = `A  legyen strukturált, részletes és gyakorlati lépéseket tartalmazzon.`
const personality = "Te vagy a világ legokosabb regisztrált dietetikus asszisztens."// Pretend you are the world’s smartest registered dietitian and make a seven-day vegetarian meal plan with 1,700 calories for a woman who wants to lose one pound per week.



const openai = new OpenAI({
  apiKey: API_KEY,
  dangerouslyAllowBrowser: true 
});


export const translateText = async (text, targetLanguage) => {
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4",
      messages: [
        { role: "system", content: "You are a highly skilled translator." },
        { role: "user", content: `Translate the following text to ${targetLanguage}: "${text}"` }
      ],
      max_tokens: 1000,
    });

    return response.choices[0].message.content.trim();
  } catch (error) {
    console.error('Error in translateText:', error);
    return `Translation error: ${error.message}`;
  }
};

export const queryDiaCoach = async (fixText, customInfo, selectedMessages, setDiacoachMessage, set) => {

  const dinamicQuery = (selectedMessages.length>0 ? `Behivatkozzuk ezeket az üzeneteket: \n${
    selectedMessages.map(message => message.text).filter(Boolean).join('\n')}\n\n` : '' +
    customInfo.length>0 ? `Az instrukció: ${customInfo}` : '' +
    `${fixText}`).trim() 
  console.log(dinamicQuery);
  if (dinamicQuery == '') { return }
  const fullQuery = dinamicQuery + default_style;

  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4o",
      messages: [
        { role: "system", content: personality },
        { role: "user",   content: fullQuery }],
      stream: true,
    });

    let partialResponse = '';

    for await (const chunk of response) {
      const content = chunk.choices[0]?.delta?.content || '';
      if (content) {
        partialResponse += content;
        set({ currentResponse: partialResponse });
        await setDiacoachMessage(partialResponse);
      }
    }
    

    return partialResponse;
 } catch (error) {
    console.error('Error in queryDiaCoach:', error);
    set({ currentResponse: `Hiba történt: ${error.message}` });
    await setDiacoachMessage(`Hiba történt: ${error.message}`);
    return `Hiba történt: ${error.message}`;
  }
};

// Más LLM-mel kapcsolatos funkciók itt...