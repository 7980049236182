import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import GlobalStyles from './styles/GlobalStyles';
import ChatWindow from './components/ChatWindow';
import Login from './components/Login';
import { useAuth } from './contexts/AuthContext';
import useChatStore from './store/chatStore';

const AppContainer = styled.div`
  display: flex;
  height: 100vh;
`;

function App() {
  const { currentUser } = useAuth();
  const { clearChat } = useChatStore();

  return (
    <Router>
      <GlobalStyles />
      {currentUser ? (
        <AppContainer>
          <Routes>
            <Route path="/chat/:uid" element={<ChatWindow />} />
            <Route path="/" element={<ChatWindow />} />
          </Routes>
        </AppContainer>
      ) : (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;