import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import useChatStore from '../store/chatStore';

const ControlsContainer = styled.div`
  display: flex;
  padding: 12px 16px 6px 16px;
  background-color: #2c2c2c;
  border-top: 1px solid #3a3a3a;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 0px;
`;

const CustomInput = styled.textarea`
  flex: 1;
  padding: 10px 16px;
  margin-bottom: 10px;
  border: none;
  border-radius: 20px;
  background-color: #3a3a3a;
  color: #fff;
  font-size: 16px;
  resize: none;
  min-height: 40px;
  max-height: 200px;
  overflow-y: auto;

  &:focus {
    outline: none;
  }

  scrollbar-width: thin;
  scrollbar-color: #6b6b6b #3a3a3a;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #3a3a3a;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
    border-radius: 4px;
    border: 2px solid #3a3a3a;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #888;
  }
`;

const SendButton = styled.button`
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  height: 36px;
  cursor: pointer;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

const Avatar = styled.img`
  width: 90px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const AutoResizeTextarea = ({ value, onChange, onKeyDown, placeholder }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
  }, [value]);

  return (
    <CustomInput
      ref={textareaRef}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
    />
  );
};

const AssistantControls = () => {
  const { customInfo, setCustomInfo, queryLLMDiaCoach } = useChatStore();

  const handleCustomInfoChange = (e) => {
    setCustomInfo(e.target.value);
  };
  const addCustomInfoChange = (addedtext) => {
    setCustomInfo(customInfo + addedtext);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      queryLLMDiaCoach("");
    }
  };

  return (
    <ControlsContainer>
      <LeftColumn>
        <Avatar src={`${process.env.PUBLIC_URL}/pandaface.png`} alt={'DiaCoach'} />
      </LeftColumn>
      <RightColumn>
        <Row>
          <AutoResizeTextarea 
            value={customInfo} 
            onChange={handleCustomInfoChange}
            onKeyDown={handleKeyDown}
            placeholder="Custom information..."
          />
        </Row>
        <Row>
          <SendButton onClick={()=>queryLLMDiaCoach("")}>Válasz</SendButton>
          <SendButton onClick={()=>addCustomInfoChange("\nCsinálj egy részletes struktúrált étrendet!")}>Étrend</SendButton>
          <SendButton onClick={()=>addCustomInfoChange("\nKonzultáljon orvosával vagy egészségügyi szakértővel választ add!")}>Konzultáljon szakértővel</SendButton>
        </Row>
      </RightColumn>
    </ControlsContainer>
  );
};

export default AssistantControls;
